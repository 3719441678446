.footer__block.footer__block--menu h2,
.footer__block.footer__block--menu ul.footer__quicklinks li {
  text-align: center !important; 
}
h1.expnmueblesxcat {
  font-family: var(--FONT-STACK-BODY);
  font-weight: var(--FONT-WEIGHT-BODY);
  font-style: var(--FONT-STYLE-BODY);
  font-size: 32px;
}
#SectionColumns--template--19448019648831__0ebb03c2-154c-4428-9d16-05ea29f6d07e .columns {
  justify-content: center;
}
#CollectionsList--template--19448019976511__c86b52c1-84d0-471c-964d-53951887595a .collection-item {
  background: transparent;
}


#Hero--template--19448019353919__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__button a.btn {
  background-color:  #232c33;
  border-color:  #232c33;
  color: #fff;
}
#Hero--template--19448019353919__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__button a.btn:hover {
  background-color:  #ffffff !important;
  border-color:  #232c33;
  color: #232c33;
}
#Hero--template--19448019353919__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__button a.btn:after {
  background-color:  #ffffff !important;
}


@media only screen and (min-width: 750px) {
  #double-section-template--19448019910975__73191edd-64c6-4f39-84c6-1ee41f26b9c1 .screen-height-three-quarters {
    min-height: 272px !important;
  }
}
#double-section-template--19448019910975__73191edd-64c6-4f39-84c6-1ee41f26b9c1 .section-double__figure {
  flex: 0 0 35%;
}
#double-section-template--19448019910975__73191edd-64c6-4f39-84c6-1ee41f26b9c1 .section-double__figure .image__fill img {
  object-fit: contain;
}
#double-section-template--19448019910975__73191edd-64c6-4f39-84c6-1ee41f26b9c1 .section-double__aside {
  flex-basis: 65%;
}
#double-section-template--19448019910975__73191edd-64c6-4f39-84c6-1ee41f26b9c1 .section-double__aside .hero__content {
  padding: 0px !important;
}

#CustomCode--template--20459315298623__f68c21cd-1de5-4e6d-9a79-a596e07d6e7f {
  background-color: #f4f2ee;
}
.ciudades h3 {
  color:#232c33;
  padding: 0px;
  margin: 0px;
  font-family: "DM Sans";
  font-size: 28px;
  text-align: center;
}
.ciudades h3 span {
  margin: 0 25px;
}

#shopify-section-template--20459315298623__4e338a61-0b77-4e26-b2df-faae2b5dab03 {
  width: 100%;
 float: left;
 /*background-color: #3b3e2d;*/
 background-image: url('https://cdn.shopify.com/s/files/1/0756/7025/7983/files/beneficios-de-comprar-en-creataproyectos-muebles-de-exterior-creatamuebles-dot-com-1920px-700px.jpg?v=1694805735');
 background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#CustomCode--template--20459315298623__4e338a61-0b77-4e26-b2df-faae2b5dab03 {
  background-color: transparent;
}
.bann_compr_muebles_ext {
  padding: 50px 0;
}
.bann_compr_muebles_ext h2 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 28px;
  text-align: center;
  margin: 0px 0px 50px;
  padding: 0px;
  line-height: normal;
}
.bann_compr_muebles_ext .left {
    width: 100%;
    max-width: 50%;
}
.bann_compr_muebles_ext .left img {
  width: 450px;
}
.bann_compr_muebles_ext .right {
  max-width: 50%;
  width: 100%;
}
.bann_compr_muebles_ext .right h3 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  text-align: left;
  margin: 0px 0px 25px;
  padding: 0px 0px 25px;
  line-height: normal;
  border-bottom: 1px solid #fff;
}
.bann_compr_muebles_ext .right h3:last-child {
    border-bottom: none;
}




#highlights--template--19448019910975__891f2809-7ad4-4df5-a3bf-8f8b9a41023a .highlights__item {
  padding: 0px !important;
  margin-bottom: 0px !important;
}
#highlights--template--19448019910975__891f2809-7ad4-4df5-a3bf-8f8b9a41023a .highlights__item .highlights__item__background {
  border: none !important;
}



#Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content,
#Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content,
#Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content,
#Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content,
#Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content,
#Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content,
#Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content {
  padding-left: 0px;
}
#Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content h2,
#Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content h1,
#Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content h2,
#Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content h2,
#Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content h2,
#Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content h1,
#Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content h2 {
 text-align: left;
}
#Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content .hero__button,
#Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content .hero__button,
#Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content .hero__button,
#Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content .hero__button,
#Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content .hero__button,
#Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content .hero__button,
#Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content .hero__button {
  justify-content: start;
}



#Faq--template--19448020173119__about .accordion .accordion__content h3 {
  text-align: center;
  margin-top: 1.25em;
}


#Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content {
  position: relative;
  width: 100%;
}
#Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content h1 {
  position: absolute;
  left: 0;
  top: -75px;
  text-transform: uppercase;
  text-align: left;
}
#Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content .hero__button {
  position: absolute;
  left: 0;
  bottom: -35px;
}



#BrickSection--template--19810255601983__e92aff1f-b740-417b-8fa6-2464a2f4a8a0,
#BrickSection--template--19810255601983__e92aff1f-b740-417b-8fa6-2464a2f4a8a0 .brick__block__text,
#BrickSection--template--19810255601983__e92aff1f-b740-417b-8fa6-2464a2f4a8a0 .brick__block__text .hero__content,

#Rte--template--19840839385407__8c35b171-ddc1-4186-abdd-061cb5990133,
#Rte--template--19840839385407__8c35b171-ddc1-4186-abdd-061cb5990133 h2,
#BrickSection--template--19840839385407__15b3b314-7ea1-42f3-be4f-ef1831f82248,
#BrickSection--template--19840839385407__15b3b314-7ea1-42f3-be4f-ef1831f82248 .brick__block__text,
#BrickSection--template--19840839385407__15b3b314-7ea1-42f3-be4f-ef1831f82248 .brick__block__text .hero__content,
#BrickSection--template--19840839385407__15b3b314-7ea1-42f3-be4f-ef1831f82248 .brick__block,
#SectionColumns--template--19448019779903__572ed20e-865a-431f-b12e-a258395f6bbe .columns .column .column__icon {
 background-color: #f3f1ee; /* #f3f1ed */
}
#SectionColumns--template--19448019779903__572ed20e-865a-431f-b12e-a258395f6bbe .columns .column .column__icon {
  padding-bottom: 20px;
  height: 205px;
}



#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6,
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 #CustomCode--template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed #CustomCode--template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 #CustomCode--template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 {
  background-color: #f3f1ee;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 #CustomCode--template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed #CustomCode--template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 #CustomCode--template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 {
  padding-left: 0px;
  padding-right: 0px;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left {
  padding: 0 25px;
  width: 25%;
  position: relative;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left::before {
  content: '';
  position: absolute;
  right: 12.5px;
  width: 1px;
  height: 100%;
  background-color: #232c33;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left img,
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .right img {
    display: block;
    width: 100%;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left img {
  margin-left: -25px;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .center {
  width: 35%;
  padding: 0 10px;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .center h2,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left h2,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left h2 {
    font-family: "DM Sans";
    text-align: center;
    font-size: calc(var(--font-6) * var(--FONT-ADJUST-HEADING));
    font-style: var(--FONT-STYLE-HEADING);
    font-weight: var(--FONT-WEIGHT-HEADING);
    line-height: 1.2;
    color: var(--text);
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .center p,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left p,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left p {
  font-family: var(--FONT-STACK-BODY);
  font-weight: var(--FONT-WEIGHT-BODY);
  font-style: var(--FONT-STYLE-BODY);
  font-size: calc(var(--font-2) * var(--FONT-ADJUST-BODY));
  padding: 0;
  border: 0;
  font: inherit;
  margin: 0 0 var(--line);
  color: var(--text);
  text-align: left;
  font-size: 14px;
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .center a,
.design-resistent-ext .left a {
  appearance: none;
  padding: var(--btn-top) var(--btn-left);
  border: 1px solid var(--btn-border);
  border-radius: var(--btn-radius);
  background-color: var(--btn-bg);
  font-family: var(--BTN-FONT-STACK);
  font-size: var(--BTN-FONT-SIZE);
  line-height: var(--line-height-normal);
  color: var(--btn-text);
  font-style: var(--BTN-FONT-STYLE);
  font-weight: var(--BTN-FONT-WEIGHT);
  letter-spacing: var(--BTN-LETTER-SPACING);
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  text-transform: var(--BTN-UPPERCASE);
  transition: .25s ease-out;

  background: linear-gradient(to right,currentColor,currentColor);
  background-size: 100% 1px;
  background-position: 50% 86%;
  background-repeat: no-repeat;
  transition: color .25s ease-in,background-size .25s ease-in;
  line-height: 2;
  padding: 5px 0;  
}
#shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .right,
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .right,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .right {
  width: 40%;
}


#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 {
 width: 100%;
 display: inline-block;
}
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box {
  padding: 1em 0;
}
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left {
  width: 60%;
}
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left h2,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left h2 {
  text-align: left;
  font-size: 28px;
}
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left p,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left p {
  font-size: 18px;
  font-weight: 500;
}
#shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .right a,
#shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .right a {
  color: #fff;
  text-transform: var(--BTN-UPPERCASE);
  padding: var(--btn-top) var(--btn-left);
  background-color: #232c33;
  float: right;
}



#CustomCode--template--20459315298623__72825315-709a-4846-9ab9-a0fd379a9a48,
#CustomCode--template--20459315298623__9cb7de25-1a42-44c6-9f06-75de0c7f865b,
#CustomCode--template--19809712374079__da0c22d2-eba1-4807-8002-339b7636a049,
#CustomCode--template--19810255601983__e6a22664-63bc-4c5e-b14d-45878266ce81 {
 display: contents;
}
.person-espacios-airelibre,
.design-resistent-ext,
.banner-princ-secc-page-comerdor-ext,
.banner-princ-secc-page-salas-ext {
  /*padding: 0px;
  margin: 0px;*/
  display: flex;
}
.person-espacios-airelibre .left,
.design-resistent-ext .right,
.banner-princ-secc-page-comerdor-ext .right,
.banner-princ-secc-page-salas-ext .right {
  background-image: url(https://creata.com.mx/cdn/shop/files/muebles-de-exterior-con-materiales-resistentes-aluminio-rattan-mimbre-creataproyectos-550px-550px.jpg?v=1692029400&width=540);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0;
  width: 40%;
}
.design-resistent-ext .right {
  background-image: url(https://cdn.shopify.com/s/files/1/0756/7025/7983/files/muebles-de-exterior-que-hacen-un-lugar-instagrameable-para-tu-proyecto-negocio-viral-creataproyectos-dot-com-823px-500px_6ae9f40f-026e-46fb-89b6-e1889905605b.jpg?v=1696865406);
  border-top-left-radius: 250px;
  border-bottom-left-radius: 250px;
  width: 55%;
}
.banner-princ-secc-page-comerdor-ext .right {
  background-image: url(https://cdn.shopify.com/s/files/1/0756/7025/7983/files/comedores-de-jardin-comedores-de-exterior-creatamuebles-dot-com-mx-1000px-800px.jpg?v=1696866864);
}
.banner-princ-secc-page-salas-ext .right {
  background-image: url(https://cdn.shopify.com/s/files/1/0756/7025/7983/files/salas-exteriores-sofa-y-loveseat-exterior-muebles-exterior-creatamuebles-dot-com-700px-700px_affa4af6-0d38-4729-b18c-828c9650d196.jpg?v=1696867725);
}
.person-espacios-airelibre .right {
 padding: var(--outer) var(--outer) calc(var(--outer) - var(--line));
 background-color:#e7e4dc;
 border-top-right-radius: 250px;
 border-bottom-right-radius: 250px;
 padding-right: 150px;
 width: 60%;
}
.person-espacios-airelibre .right p,
.person-espacios-airelibre .right ul li,
.design-resistent-ext .left p {
  font-size: calc(var(--font-4) * var(--FONT-ADJUST-BODY));
  font-family: var(--FONT-STACK-BODY);
  font-weight: var(--FONT-WEIGHT-BODY);
  font-style: var(--FONT-STYLE-BODY);
  text-transform: none;
  letter-spacing: 0px;
  line-height: 1.2;
  color: var(--text);
}
.person-espacios-airelibre .right ul {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-left: 0px;
}
.person-espacios-airelibre .right ul li {
  font-size: 22px;
  list-style: none;
}


.design-resistent-ext .left,
.banner-princ-secc-page-comerdor-ext .left,
.banner-princ-secc-page-salas-ext .left {
 padding: var(--outer) var(--outer) calc(var(--outer) - var(--line));
 background-color:#e7e4dc;
 border-top-left-radius: 175px;
 border-bottom-left-radius: 175px;
 padding-left: 150px;
 width: 60%;
  text-align: center;
}
.design-resistent-ext .left {
  padding-top: 125px;
  padding-bottom: 125px;
  border-top-left-radius: 250px;
  border-bottom-left-radius: 250px;
}
.design-resistent-ext .left p {
  font-size: 24px;
  text-align: center;
}


.banner-princ-secc-page-comerdor-ext .left,
.banner-princ-secc-page-salas-ext .left {
  background-color: #22292e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 150px;
}
.banner-princ-secc-page-comerdor-ext .left h1,
.banner-princ-secc-page-salas-ext .left h1 {
 font-size: 62px;
 font-family: var(--FONT-STACK-HEADING);
 font-style: var(--FONT-STYLE-HEADING);
 font-weight: var(--FONT-WEIGHT-HEADING);
 line-height: normal;
  margin: 0px;
  color: #fff;
}






.person-espacios-airelibre {
  background-image: linear-gradient(90deg, #e4e1d9 50%,#fff 50%);
}
.person-espacios-airelibre .left {
  width: 50%;
  border-top-right-radius: 250px;
  border-bottom-right-radius: 250px;
}

.design-resistent-ext {
  background-image: linear-gradient(90deg, #fff 50%,#e4e1d9 50%);
}


.banner-princ-secc-page-salas-ext,
.banner-princ-secc-page-comerdor-ext {
 background-image: linear-gradient(90deg, #fff 50%,#22292e 50%);
}
.banner-princ-secc-page-salas-ext .left,
.banner-princ-secc-page-comerdor-ext .left {
  border-top-left-radius: 250px;
  border-bottom-left-radius: 250px;
}
.banner-princ-secc-page-salas-ext .right,
.banner-princ-secc-page-comerdor-ext .right {
  width: 55%;
  border-top-left-radius: 250px;
  border-bottom-left-radius: 250px;
}



#Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content {
  position: relative;
  left: -205px;
}

#Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content {
  position: relative;
  left: -155px;
}

#Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content {
  position: relative;
  left: -72px;
}



ul.rs-top {
 position: relative;
 padding: 0;
 margin: 0;
}
ul.rs-top li {
 list-style: none;
 margin-bottom: 0px;
 position: fixed;
 right: 42px;
 bottom:28px;
 z-index: 99;
}
ul.rs-top li a img {
 width: 60px;
}

.btn--scroll-top {
  left: 50px;
  bottom: 80px;
}


#SearchLoop .grid-item {
  display: none;
}
#SearchLoop .grid-item.product-item {
  display: block;
}





#shopify-section-template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be,
#shopify-section-template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1,
#shopify-section-template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed,
#shopify-section-template--19728178708799__20c67abb-9f6d-4770-a4ed-2efccef8be2c,
#shopify-section-template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4,
#shopify-section-template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06,
#shopify-section-template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b,
#shopify-section-template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6,
#shopify-section-template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 {
 margin-bottom: 50px;
}
#CustomCode--template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be,
#CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1,
#CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed,
#CustomCode--template--19728178708799__20c67abb-9f6d-4770-a4ed-2efccef8be2c,
#CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4,
#CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06,
#CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b,
#CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6,
#CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 {
 background-color: #f3f1ed;
 max-width: 100%;
}
#CustomCode--template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be .hero__content,
#CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 .hero__content,
#CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed .hero__content,
#CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 .hero__content {
 position: relative;
 left: -50px;
}
#CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed .hero__content {
 left: -75px; 
}
#CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 .hero__content,
#CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 .hero__content,
#CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__content {
 left: -150px;
 position: relative;
}
#CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b .hero__content,
#CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 .hero__content {
  left: -100px;
  position: relative;
}
#CustomCode--template--19728178708799__20c67abb-9f6d-4770-a4ed-2efccef8be2c h2 {
 font-size: 46px;
 text-align: left;
}
#CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 h2,
#CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 h2,
#CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b h2,
#CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 h2,
#CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 h2 {
  text-align: left;
}
#CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 h2 {
  text-transform: uppercase;
}
#CustomCode--template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be .hero__button,
#CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 .hero__button,
#CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed .hero__button,
#CustomCode--template--19728178708799__20c67abb-9f6d-4770-a4ed-2efccef8be2c .hero__button,
#CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 .hero__button,
#CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 .hero__button,
#CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b .hero__button,
#CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__button,
#CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 .hero__button {
 justify-content: flex-start;
}




@media(max-width: 768px) {
  #CustomCode--template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be .hero__content,
  #CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed .hero__content,
  #CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 .hero__content,
  #CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 .hero__content,
  #CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b .hero__content,
  #CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__content,
  #CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 .hero__content {
   left: 0px;
  }
  #CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 .hero__content {
   left: -20px;
  }
  #CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__content {
    padding-right: 0px;
  }
  #shopify-section-template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be h2,
  #shopify-section-template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 h2,
  #shopify-section-template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed h2,
  #shopify-section-template--19728178708799__20c67abb-9f6d-4770-a4ed-2efccef8be2c h2,
  #shopify-section-template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 h2,
  #shopify-section-template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 h2,
  #shopify-section-template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b h2,
  #shopify-section-template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 h2 {
   font-size: 32px;
   margin-bottom: 0px;
   text-align: center;
  }
  #shopify-section-template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 h2 {
    font-size: 14px;
    text-align: right;
  }
  #CustomCode--template--19448020009279__ad5b3dd6-bbb3-43f5-b4fd-293bd0a539be .hero__button,
  #CustomCode--template--19448019747135__2d0cf2a6-4492-4472-973f-5c770f95f2a1 .hero__button,
  #CustomCode--template--19448020074815__f3d59ca4-429f-40d4-a457-e8f7a7fc73ed .hero__button,
  #CustomCode--template--20564943929663__2a330051-b1d2-4f2d-8cb6-23b0850f9fd4 .hero__button,
  #CustomCode--template--19448019910975__e10e83f6-d1b4-4e1d-a4df-62eefba43b06 .hero__button,
  #CustomCode--template--19448019943743__05d57db9-bfa0-4a3c-a701-f4a72c8d0a5b .hero__button,
  #CustomCode--template--19810255601983__e9600c5e-ef98-467e-926e-5c8c25c88277 .hero__button {
   justify-content: center;
  }
  #CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__button {
   justify-content: flex-end;
   margin: 0;
  }
  #CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__button a {
    font-size: 10px;
    padding-left: 8px;
    padding-right: 8px;
  }
  #CustomCode--template--19809712374079__63c41f40-59ea-48e4-8805-6a131ebdf8d6 .hero__images {
    display: block;
  }





  
  #SectionColumns--template--19448019976511__6a305410-095a-420b-aaf1-9fef1e12d3ac .columns--mobile-slider {
    display: flex !important;
    margin: 0px;
    padding-left: 0px;
    scroll-padding: 0px;
  }
  #SectionColumns--template--19448019976511__6a305410-095a-420b-aaf1-9fef1e12d3ac .columns--mobile-slider .column,
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1 .columns--mobile-slider .column {
    width: 100%;
  }
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1 .columns--mobile-slider .column .image-wrapper img {
    top: 0px;
  }



  #TextProducts--template--19448019648831__19dfbc74-cad1-4a6a-86b0-7627ce0a81f0 .heading-size-8 {
    margin: 0px !important;
    font-size: 18px !important;
    line-height: normal !important;
  }
  #TextProducts--template--19448019648831__19dfbc74-cad1-4a6a-86b0-7627ce0a81f0 .inline-image {
    --image-width: 37.5px !important;
    margin: 0px 5px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  



  

  .bann_compr_muebles_ext h2 {
    font-size: 22px;
  }
  .bann_compr_muebles_ext .left,
  .bann_compr_muebles_ext .right {
    max-width: 100%;
  }
  .bann_compr_muebles_ext .left img {
    width: 300px;
    display: block;
    margin: 0 auto 25px;
  }
  .bann_compr_muebles_ext .right h3 {
    font-size: 16px;
  }
  

  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content h2 {
    text-align: center;
    font-size: 20px;
    color: #eae6de;
  }
  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content .hero__button {
   justify-content: center;
  }

  #Hero--template--20564943929663__ff59b829-4fa1-4021-b0ae-575ea161f78b .hero__content h1 {
    color: #f3f1ee;
  }




  #CustomCode--template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 {
    display: grid;
    padding-bottom: 12.5px;
  }
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box {
    display: block;
  }
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left,
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .center, 
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .right {
    width: 100%;
  }
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .left img {
    margin: 1em 0px;
  }
  #shopify-section-template--19448019976511__1c5b7b60-7c38-495a-a635-b3c11051cbf6 .mproyectos-box .right {
    margin-top: 1em;
  }
  
  


  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content__wrapper,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content .hero__button,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content .hero__button,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content .hero__button {
    justify-content: center !important;
  }
  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content {
    left: 0px;
  }

  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content h2,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content h2,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content h2 {
    text-align: center;
  }
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content h2 {
    font-size: 26px;
    padding: 0px 22px;
  }



  #shopify-section-template--19448019910975__8af4f476-b7c5-4c26-a38a-e6b156ef8e3a .columns--slider {
    display: block !important;
    padding-left: 0px !important;
  }



  

  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box {
    display: grid;
    padding-bottom: 24px;
  }
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left,
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .right,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .right {
    width: 100%;
  }
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left h2,
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .left p,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left h2, 
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .left p {
    text-align: center;
    /*font-size: 28px;*/
  }
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .right,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .right {
    text-align: center;
  }
  #shopify-section-template--19448019910975__eae09b51-87fa-4c84-9318-cfbd2964c0ed .listonminterior-box .right a,
  #shopify-section-template--19448019943743__c6d79102-11a9-4fcd-896c-161b72c2b906 .listonminterior-box .right a {
    float: none;
  }




  #Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content h1 {
   left: 50%;
   font-size: 14px;
   top: -30px;
   text-align: center;
  }
  #Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content .hero__button {
   left: 50%;
   margin-top: 0;
   margin-bottom: 0px;
  }
  #Hero--template--19809712374079__59c24205-d458-47fe-9718-875043ef48a1 .hero__content .hero__button .btn--primary.btn--solid {
   padding-top: 4px;
   padding-bottom: 4px;
   font-size: 12px;
   line-height: normal;
  }




  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content__wrapper,
  #Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content__wrapper,
  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content__wrapper,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content__wrapper,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content__wrapper,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content__wrapper,
  #Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content__wrapper {
    align-items: center;
  }
  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content,
  #Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content,
  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content,
  #Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content {
    padding-left: 16px;
  }
  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content h2,
  #Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content h2,
  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content h2,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content h2,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content h2,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content h1,
  #Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content h2 {
    text-align: center;
    font-size: 26px;
    padding: 0 25px;
  }
  #Hero--template--20981144715583__676bd116-76db-4b03-a2c2-516cc8fca1d4 .hero__content .hero__button,
  #Hero--template--19810255601983__83296b54-754b-446e-ad28-9134c80b98d5 .hero__content .hero__button,
  #Hero--template--19448019747135__d9155790-8845-419e-b2c7-0142c23c037a .hero__content .hero__button,
  #Hero--template--19448020074815__08d79a83-db1d-4e49-9144-2fe951e96c0d .hero__content .hero__button,
  #Hero--template--19448020009279__67ba9f27-796c-457b-b087-19d3359af526 .hero__content .hero__button,
  #Hero--template--19728178708799__eeb6adff-dd81-4010-9405-bd89bb9b5234 .hero__content .hero__button,
  #Hero--template--19448019943743__e4b7a4a8-401e-4035-a5b1-9d52f6dd0811 .hero__content .hero__button {
    justify-content: center;
    margin: 0;
  }




  #shopify-section-template--19448019976511__03edf43e-a0d8-4c76-b0a0-36471b83167a .wrapper--narrow {
   max-width: 100% !important;
   margin-left: 0px !important;
   margin-right: 0px !important;
   padding-left: 16px !important;
   padding-right: 16px !important;
  }

  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1 {
    position: relative;
  }
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1 .columns.columns--mobile-slider {
    position: relative;
    padding-right: 16px;
  }
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1::before,
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1::after {
    content: "";
    position: absolute;
    /*top: 85%;
    left: 0px;*/
    top: 46%;
    left: -12.5px;
    width: 0;
    height: 0;
    line-height: normal;
    background-color: #eaff0000;
    border-width: 20px;
    border-style: solid;
    border-color: transparent #ac8049 transparent transparent;
  }
  #SectionColumns--template--19844659020095__e9af38cc-47b9-48a6-a4a9-23bad32407e1::after {
    border-color: transparent transparent transparent #ac8049;
    left: auto;
    /*right: 0px;*/
    right: -8px;
  }

  

  
  
  #shopify-section-template--19448019910975__891f2809-7ad4-4df5-a3bf-8f8b9a41023a .body-size-3 {
    top: 50% !important;
    height: 50% !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px !important;
  }



  .ciudades h3 span {
    display: block;
    font-size: 2px;
    color: #cacaca;
  }  



  .person-espacios-airelibre,
  .banner-princ-secc-page-comerdor-ext,
  .banner-princ-secc-page-salas-ext {
    display: grid;
  }
  .person-espacios-airelibre,
  .design-resistent-ext {
    background-image: none;
    position: relative;
    z-index: 10;
  }
  .banner-princ-secc-page-salas-ext,
  .banner-princ-secc-page-comerdor-ext {
    background-image: linear-gradient(0deg, #22292e 55%,#fff 45%);
  }
  .person-espacios-airelibre .left {
    flex: 1;
    order: 2;
    width: 100%;
    border-top-right-radius: 250px;
    border-top-left-radius: 250px;
    border-bottom-right-radius: 0;
    position: relative;
  }
  .person-espacios-airelibre .left::before,
  .design-resistent-ext .right::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #e7e4dc;
    z-index: -2;
    top: 0;
  }
  .person-espacios-airelibre .right,
  .design-resistent-ext .left {
    text-align: center;
    padding: 100px 50px 50px;
    border-top-left-radius: 250px;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;
  }

  .design-resistent-ext {
    display: block;  
  }
  /*.design-resistent-ext .left,*/
  .banner-princ-secc-page-comerdor-ext .left,
  .banner-princ-secc-page-salas-ext .left {
    width: 100%;
    padding: 75px 50px 50px;
    border-top-left-radius: 175px;
    border-top-right-radius: 175px;
    border-bottom-left-radius: 0px;
  }
  .banner-princ-secc-page-comerdor-ext .left,
  .banner-princ-secc-page-salas-ext .left {
    border-top-left-radius: 250px;
    border-top-right-radius: 250px;
    padding: 100px 50px 75px;
  }
  .banner-princ-secc-page-salas-ext .right,
  .banner-princ-secc-page-comerdor-ext .right {
    border-top-left-radius: 250px;
    border-top-right-radius: 250px;
    border-bottom-left-radius: 0px;
  }
  
  .design-resistent-ext .right {
    width: 100%;
    position: relative;
    border-top-left-radius: 250px;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .banner-princ-secc-page-comerdor-ext .left h1,
  .banner-princ-secc-page-salas-ext .left h1 {
    font-size: 40px;
  }

  .banner-princ-secc-page-comerdor-ext .right,
  .banner-princ-secc-page-salas-ext .right {
    width: 100%;
  }
}